import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import ComponentLoader from "./component-loader";

const SignUp = ComponentLoader(
  lazy(() => import("../layouts/authentication/sign-up/sign-up"))
);
const SignIn = ComponentLoader(
  lazy(() => import("../layouts/authentication/sign-in/sign-in"))
);
const OTP = ComponentLoader(
  lazy(() => import("../layouts/authentication/otp/otp"))
);

const Error = ComponentLoader(
  lazy(() => import("../layouts/public/employee-onboard/error/error"))
);
const ResetPasswordRequest = ComponentLoader(
  lazy(() =>
    import(
      "../layouts/authentication/reset-password-request/reset-password-request"
    )
  )
);
const ResetPassword = ComponentLoader(
  lazy(() => import("../layouts/authentication/reset-password/reset-password"))
);

const AuthRoutes = [
  {
    path: "",
    // element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <SignUp />,
      },
      {
        path: "reset-password-request",
        element: <ResetPasswordRequest />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "otp",
        element: <OTP />,
      },

      {
        path: "error",
        element: <Error />,
      },
      {
        path: "*",
        element: <Navigate to={""} />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
];

export default AuthRoutes;
