import { useAuth } from "context/auth";
import { enums } from "global/enums";
import { decryptJSON } from "global/security";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Api from "utils/api";

function CardHoldersLimit() {
  const { currentUser, cardholders, setCardholders } = useAuth();
  const limit = currentUser?.subscription?.limit;

  const handleGettingCardHolders = async () => {
    try {
      const { error, response } = await Api(
        `/${enums.ROLES[currentUser.role]}/getCardHolders`,
        "get"
      );

      if (response) {
        const decryptedData = decryptJSON(response.data);
        const users = decryptedData.filter((e) => e?.disable === false);
        setCardholders(users?.length);
      }
    } catch (err) {}
  };

  const onLoad = () => {
    handleGettingCardHolders();
  };

  useEffect(onLoad, []);

  const getUsagePercentage = () => {
    if (limit == "any") return "0%";
    const percentage = (cardholders / limit) * 100;
    if (percentage > 100) return "100%";
    return percentage + "%";
  };

  return (
    <div className="tw-flex tw-flex-col tw-w-[200px] tw-justify-center tw-max-w-full sm:tw-mr-5 tw-mb-8 sm:tw-mb-0 tw-mt-3 sm:tw-mt-0">
      <div className="tw-text-gray-800 tw-text-sm tw-flex tw-justify-between tw-mb-1">
        <span className="tw-flex tw-justify-start">
          <Link className="hover:tw-underline" to="/cardholders">
            Cardholders
          </Link>
        </span>
        <span className="tw-flex tw-justify-end">
          {limit == "any" ? cardholders : cardholders + "/" + limit}
        </span>
      </div>
      <div className="tw-w-full tw-relative">
        <div className="tw-bg-[#fad745] tw-w-full tw-h-2 tw-rounded-sm tw-absolute"></div>
        <div
          className="tw-bg-mint tw-h-2 tw-rounded-sm tw-absolute"
          style={{ width: getUsagePercentage() }}
        ></div>
      </div>
      <div className="tw-w-full tw-mt-3 tw-text-right">
        <Link
          className="tw-block tw-text-electricGreen hover:tw-underline tw-text-sm"
          to="/subscribe"
        >
          Manage subscription ➔
        </Link>
      </div>
    </div>
  );
}

export default CardHoldersLimit;
