import images from "assets/images/Guidelines_page-0001.jpg";
import { decryptJSON } from "global/security";
import { Outlet, useLocation } from "react-router-dom";

function Useronboard() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const key = queryParams.get("key");
  const decryptedUser = decryptJSON(key.replace(/ /g, "+"));

  return (
    <div className="tw-grid md:tw-h-screen tw-items-stretch md:tw-grid-cols-2 md:tw-p-0 tw-p-5">
      <div className="md:tw-flex tw-items-center tw-justify-center d:tw-h-screen tw-hidden tw-bg-[rgb(44,48,83)]">
        <div className="tw-fixed tw-top-20 tw-left-20"></div>
        <div className="tw-block tw-w-96">
          <img src={images} alt="" />
        </div>
      </div>

      <div class="tw-flex md:tw-items-center md:tw-justify-center md:tw-px-4 tw-py-8">
        <div class="md:tw-max-w-sm tw-w-full">
          <h1 class="tw-font-heading  md:tw-text-4xl tw-font-semibold tw-mb-2 tw-text-4xl">
            Hi {decryptedUser?.name}
            <span class="tw-text-electricGreen">,</span>
          </h1>
          <strong class="tw-block tw-mb-4 tw-text-xl">
            Create a password to start your session
          </strong>

          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Useronboard;
