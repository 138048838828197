const ACCOUNT_TYPE = {
  ORGANIZATION: "organization",
  PERSONAL: "personal",
  ENTERPRISE: "enterprise",
};

const STATUS = {
  DOWNLOADED: "downloaded",
  PASS_CREATED: "pass_created",
};

const ROLE = {
  // SUPER_ADMIN: "super_admin",
  // ADMIN: "admin",
  // EMPLOYEE: "employee",

  SUPER_ADMIN: "super_admin",
  ENT_ADMIN: "ent_admin",
  ENT_EMPLOYEE: "ent_employee",
  ORG_ADMIN: "org_admin",
  ORG_EMPLOYEE: "org_employee",
  USER: "user",
};

export { ACCOUNT_TYPE, STATUS, ROLE };
