import React from "react";
import RegisterHeader from "../component/register-header";
import Stepper from "./component/stepper";
import { Outlet } from "react-router-dom";

function OrganisationOnboardLayout() {
  return (
    <>
      <RegisterHeader />
      <Stepper />
      <Outlet />
    </>
  );
}

export default OrganisationOnboardLayout;
