import { useAuth } from "context/auth";
import { DesignProvider } from "context/design.js";
import { GRACE_PERIOD_IN_DAYS } from "enums";
import { SUBSCRIPTION_PERIODS } from "layouts/authentication/register/organisation/subscription/data/plans";
import { Link, Outlet } from "react-router-dom";
import Header from "./Header";

function AdminLayout() {
  const { currentUser } = useAuth();
  const today = new Date();

  const isSubscriptionExpired = () => {
    return new Date(currentUser?.subscription?.nextPaymentDate) < today;
  };

  const disableServices = () => {
    // if (
    //   currentUser.account?.subscription?.plan?.subscriptionPeriod ===
    //   SUBSCRIPTION_PERIODS.MONTHLY
    // ) {
    //   return (
    //     new Date(currentUser?.account?.subscription?.nextPaymentDate) < today
    //   );
    // }

    // if (
    //   currentUser.account?.subscription?.plan?.subscriptionPeriod ===
    //   SUBSCRIPTION_PERIODS.ANNUALLY
    // ) {
    //   const nextPaymentDate = new Date(
    //     currentUser?.account?.subscription?.nextPaymentDate
    //   );
    //   nextPaymentDate?.setDate(
    //     nextPaymentDate?.getDate() + GRACE_PERIOD_IN_DAYS
    //   );
    //   return nextPaymentDate < today;
    // }

    // if (
    //   parseInt(currentUser?.account?.subscription?.plan?.subscriptionPeriod) >
    //   -1
    // ) {
    //   return (
    //     new Date(currentUser?.account?.subscription?.nextPaymentDate) < today
    //   );
    // }

    // return false;
    return new Date(currentUser?.subscription?.nextPaymentDate) < today;
  };

  return (
    <DesignProvider>
      <div className="tw-min-h-screen tw-flex tw-flex-col">
        <div className="tw-flex-grow">
          <div className="tw-container xl:tw-max-w-screen-xl tw-mx-auto tw-p-7">
            <Header />
            <Outlet />
          </div>
          {disableServices() && (
            <div className="tw-w-full tw-h-screen tw-border-t-[6px] tw-border-error tw-fixed tw-top-0 tw-z-50">
              <div className="tw-bg-black tw-opacity-60 tw-absolute tw-pointer-events-none tw-top-0 tw-z-40 tw-w-full tw-h-full"></div>
              <div className="tw-bg-error tw-pb-1 tw-text-sm tw-text-white tw-z-50 tw-max-w-sm tw-rounded-b-lg tw-mx-auto tw-text-center tw-relative">
                Subscription Expired,{" "}
                <Link
                  className="tw-text-white hover:tw-underline tw-font-bold"
                  to="/subscribe"
                >
                  Renew here ➔
                </Link>
              </div>
            </div>
          )}
          {isSubscriptionExpired() && (
            <div className="tw-w-full tw-border-t-[6px] tw-border-error tw-fixed tw-top-0 tw-z-50">
              <div className="tw-bg-error tw-pb-1 tw-text-sm tw-text-white tw-z-50 tw-max-w-sm tw-rounded-b-lg tw-mx-auto tw-text-center tw-relative">
                Subscription Expired,{" "}
                <Link
                  className="tw-text-white hover:tw-underline tw-font-bold"
                  to="/subscribe"
                >
                  Renew here ➔
                </Link>
              </div>
            </div>
          )}
        </div>
        {/* <Footer /> */}
      </div>
    </DesignProvider>
  );
}

export default AdminLayout;
