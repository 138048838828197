const CryptoJS = require("crypto-js");

const secretKey = "DDhsZnfpAhpYk45NYdQ-P3XnuvaA3FqTO_JQJan7";

function deriveKey() {
  const salt = CryptoJS.lib.WordArray.random(16);
  const key = CryptoJS.PBKDF2(secretKey, salt, {
    keySize: 256 / 32,
    iterations: 1000,
  });
  return { key, salt: salt.toString(CryptoJS.enc.Base64) };
}

export function encryptJSON(data) {
  const jsonString = JSON.stringify(data);
  const { key, salt } = deriveKey();
  const encrypted = CryptoJS.AES.encrypt(jsonString, key.toString()).toString();
  return salt + ":" + encrypted;
}

export function decryptJSON(encryptedData) {
  const [salt, encrypted] = encryptedData?.split(":");
  const key = CryptoJS.PBKDF2(secretKey, CryptoJS.enc.Base64.parse(salt), {
    keySize: 256 / 32,
    iterations: 1000,
  });
  const bytes = CryptoJS.AES.decrypt(encrypted, key.toString());
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);

  try {
    return JSON.parse(decrypted);
  } catch (error) {
    return null;
  }
}

export const parseBool = (value) => {
  return value === "true" || value === true;
};
