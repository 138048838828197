import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import ComponentLoader from "./component-loader";
import OrganisationOnboardLayout from "layouts/authentication/register/organisation/onboard";
import { getCurrentSelectedPlan } from "global";

// ORGANISATIONAL
const Organization = ComponentLoader(
  lazy(() =>
    import("../layouts/authentication/register/organisation/organization")
  )
);

const Design = ComponentLoader(
  lazy(() => import("../layouts/authentication/register/organisation/design"))
);

const Subscription = ComponentLoader(
  lazy(() =>
    import(
      "../layouts/authentication/register/organisation/subscription/subscription"
    )
  )
);

const PayByCard = ComponentLoader(
  lazy(() =>
    import(
      "../layouts/authentication/register/organisation/subscription/paybycard"
    )
  )
);

const ContactSales = ComponentLoader(
  lazy(() =>
    import(
      "../layouts/authentication/register/organisation/subscription/submiterequest"
    )
  )
);

const AvailablePlans = ComponentLoader(
  lazy(() =>
    import(
      "../layouts/authentication/register/organisation/subscription/available-plans"
    )
  )
);

const PaymentSuccessOkHa = ComponentLoader(
  lazy(() =>
    import(
      "../layouts/authentication/register/organisation/subscription/payment-success-processing"
    )
  )
);

const PaymentFailed = ComponentLoader(
  lazy(() =>
    import(
      "../layouts/authentication/register/organisation/subscription/payment-failed"
    )
  )
);

const Steps = [
  <Organization />,
  <Design description="Create your organisation's default card design. You can update this at  any time." />,
  <Subscription />,
];

const OnboardOrganisationRoutes = (step) => [
  {
    path: "register",
    element: <OrganisationOnboardLayout />,
    children: [
      {
        path: "",
        index: true,
        element: Steps[step - 1],
      },
      {
        path: "pay-by-card",
        element: <PayByCard />,
      },
      {
        path: "contact",
        element: <ContactSales />,
      },
      {
        path: "available-plans",
        element: <AvailablePlans />,
      },
    ],
  },
  {
    path: "payment-success",
    element: <PaymentSuccessOkHa />,
  },
  {
    path: "payment-failed",
    element: <PaymentFailed />,
  },
  {
    path: "*",
    element: <Navigate to={"register"} />,
  },
];

export default OnboardOrganisationRoutes;
