import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import ComponentLoader from "./component-loader";
import OrganisationOnboardLayout from "layouts/authentication/register/organisation/onboard";

// ORGANISATIONAL
const New_Organization = ComponentLoader(
  lazy(() =>
    import("../layouts/authentication/register/organisation/new_organization")
  )
);

const New_Design = ComponentLoader(
  lazy(() =>
    import("../layouts/authentication/register/organisation/new_design")
  )
);

const Steps = [
  <New_Organization />,
  <New_Design description="Create your organisation's default card design. You can update this at  any time." />,
];

const OnboardOrganisationRoutes = (step) => [
  {
    path: "register",
    element: <OrganisationOnboardLayout />,
    children: [
      {
        path: "",
        index: true,
        element: Steps[step - 1],
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={"register"} />,
  },
];

export default OnboardOrganisationRoutes;
