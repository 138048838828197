export let globalState = {
  accessToken: "",
  passUrl: "",
  passType: "",
  data: {},

  setCustomerData(data) {
    this.data = data;
    localStorage.setItem("customerData", data);
  },

  getCustomerData() {
    return this.data || localStorage.getItem("customerData");
  },

  loadToken() {
    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) {
      this.accessToken = storedToken;
    }
  },

  setToken(accessToken) {
    this.accessToken = accessToken;
    localStorage.setItem("accessToken", accessToken);
  },

  setPassUrl(passUrl) {
    this.passUrl = passUrl;
    localStorage.setItem("passUrl", passUrl);
  },

  setPassType(passType) {
    this.passType = passType;
    localStorage.setItem("passType", passType);
  },

  getPassType() {
    return this.passType || localStorage.getItem("passType");
  },

  getToken() {
    return this.accessToken || localStorage.getItem("accessToken");
  },

  getPassUrl() {
    return this.passUrl || localStorage.getItem("passUrl");
  },

  clearToken() {
    this.accessToken = "";
    localStorage.removeItem("accessToken");
  },
};

globalState.loadToken();
