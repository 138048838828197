import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import appleSlice from "./apple_reducer";
import androidSlice from "./google_reducer";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  apple: appleSlice,
  android: androidSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
