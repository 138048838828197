import { AuthInit, useAuth } from "context/auth";
import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "routing/routes";
import { translations, useTranslation } from "translation";
import { persistor, store } from "./redux/store";
import Api from "utils/api";
import { decryptJSON } from "global/security";
import { enums } from "global/enums";

function App() {
  const { locale } = useTranslation();
  const routes = useRoutes(Routes());
  const { currentUser, setCurrentUser } = useAuth();

  const getLatestData = async () => {
    try {
      const { error, response } = await Api(
        `/${enums.ROLES[currentUser.role]}/getLatestData`,
        "post",
        {
          payload: {
            id: currentUser?._id,
          },
        }
      );

      if (response) {
        const decryptedData = decryptJSON(response.data);

        setCurrentUser({
          ...currentUser,
          firstName: decryptedData?.firstName,
          lastName: decryptedData?.lastName,
          email: decryptedData?.email,
          subscription: {
            ...currentUser?.subscription,
            limit: decryptedData?.limit,
            nextPaymentDate: decryptedData?.nextPaymentDate,
          },
          account: {
            ...currentUser?.account,
            pdfUrl: decryptedData?.pdfUrl || "",
          },
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (currentUser?.accessToken && currentUser?._id) {
      getLatestData();
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider locale={locale} messages={translations[locale]}>
          <AuthInit>{routes}</AuthInit>
          <ToastContainer />
          {/* <Popup /> */}
        </IntlProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
