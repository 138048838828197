import Loader from "components/loader";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearAppleUrl } from "../redux/apple_reducer";
import { clearGoogleUrl } from "../redux/google_reducer";
import * as authHelper from "utils/auth-helper";
import { useNavigate } from "react-router-dom";

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: JSON.parse(localStorage.getItem("currentUser")) || undefined,
  setCurrentUser: () => {},
  cardholders: 0,
  setCardholders: () => {},
  logout: () => {},
};

const AuthContext = createContext(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [auth, setAuth] = useState(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("currentUser")) || undefined
  );
  const [cardholders, setCardholders] = useState(0);

  const saveAuth = (auth) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const saveCurrentUser = (user) => {
    setCurrentUser(user);
    localStorage.setItem("currentUser", JSON.stringify(user));
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
    localStorage.removeItem("currentUser");
    dispatch(clearGoogleUrl());
    dispatch(clearAppleUrl());
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser: saveCurrentUser,
        cardholders,
        setCardholders,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth();
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    if (auth && auth.token) {
      const savedUser = JSON.parse(localStorage.getItem("currentUser"));
      if (savedUser) {
        setCurrentUser(savedUser);
      }
    } else {
      logout();
    }
    setShowSplashScreen(false);
  }, []);

  return showSplashScreen ? (
    <div className="tw-w-full tw-h-[100vh] tw-flex tw-flex-col tw-items-center tw-justify-center">
      <Loader size={8} />
    </div>
  ) : (
    <>{children}</>
  );
};

export { AuthInit, AuthProvider, useAuth };
