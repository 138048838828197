import axios from "axios";
import env from "../config/index";

const BASE_URL = `${env.API_URL}`;

export default async function Api(path, method, option, authToken) {
  const url = BASE_URL + path;

  const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};

  return axios[method](url, option?.payload, { headers })

    .then((response) => {
      return { response: response.data };
    })
    .catch((error) => {
      if (error.response?.status === 401) {
      }
      return { error: error.response?.data || error.message };
    });
}
