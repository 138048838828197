import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  url: "",
};

export const appleSlice = createSlice({
  name: "apple",
  initialState,
  reducers: {
    saveAppleUrl: (state, action) => {
      const { url } = action.payload;

      state.url = url;
    },
    clearAppleUrl: (state) => {
      state.url = "";
    },
  },
});

export const { saveAppleUrl, clearAppleUrl } = appleSlice.actions;

export default appleSlice.reducer;
