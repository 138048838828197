import { Environment } from "../enums";

const env = {
  CARDLY_ENGINE_ENCRYPTION_KEY: "xXXcYz8p3Uv3LmNDgusMHPYMr",
  API_URL: "http://localhost:1993/api",
  APP_URL: "http://localhost:1991",
};

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env.CARDLY_ENGINE_ENCRYPTION_KEY = "xXXcYz8p3Uv3LmNDgusMHPYMr";
  env.API_URL = "https://api.cardlysa.net/api";
  env.APP_URL = "https://app.cardlysa.net";
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.CARDLY_ENGINE_ENCRYPTION_KEY = "xXXcYz8p3Uv3LmNDgusMHPYMr";
  env.API_URL = "https://api.cardlysa.com/api";
  env.APP_URL = "https://app.cardlysa.com";
}

export default env;
