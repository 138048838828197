import { useAuth } from "context/auth";
import { ACCOUNT_TYPE } from "enums/user";
import DesignIcon from "./designIcon";
import OragnizationIcon from "./oragnizationIcon";
import SubscriptionIcon from "./subscriptionIcon";
import { useEffect } from "react";

const Steps = [
  {
    icon: <OragnizationIcon />,
    description: "Create organisation",
    next: "Design card",
  },
  {
    icon: <DesignIcon />,
    description: "Create your Design",
    next: "Choose subscription",
  },
  {
    icon: <SubscriptionIcon />,
    description: "Choose subscription",
  },
];

function Stepper() {
  const { currentUser, setCurrentUser } = useAuth();
  const currentStep = currentUser.onBoardingStep - 1;
  const isActive = (number) => currentUser.onBoardingStep === number;

  useEffect(() => {
    if (!currentUser.onBoardingStep) {
      setCurrentUser({
        ...currentUser,
        onBoardingStep: 1,
      });
    }
  }, []);

  return (
    <>
      <div className="tw-w-full">
        <div className="tw-flex md:tw-hidden tw-border tw-mx-[30px] tw-p-[10px] tw-mb-[30px] tw-rounded-[10px]">
          <div className="tw-h-[60px] tw-w-[60px]">
            {Steps[currentStep]?.icon}
          </div>
          <div className="tw-flex-grow tw-flex tw-items-center tw-justify-center tw-flex-col">
            <div className="tw-text-right tw-font-bold tw-text-[16px]">
              {Steps[currentStep]?.description}
            </div>
            {Steps[currentStep]?.next && (
              <div className="tw-text-right tw-text-[12px]">
                Next: {Steps[currentStep]?.next}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="tw-hidden md:tw-flex tw-justify-center">
        <div className="tw-flex tw-gap-[10px] tw-items-center tw-mb-[22px] md:tw-mb-[49px]">
          <div
            className={`${
              isActive(1) ? "tw-bg-electricGreen" : "tw-bg-[#BEBEBE]"
            } tw-h-[17px] tw-w-[17px] md:tw-h-[23px] md:tw-w-[23px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-white tw-text-[10px]`}
          >
            1
          </div>
          <div
            className={`${
              isActive(1) ? "tw-text-electricGreen" : "tw-text-[#BEBEBE]"
            } tw-text-[8px] md:tw-text-[18px]`}
          >
            Create organisation
          </div>
          <div className="tw-mr-[15px] tw-ml-[5px] tw-text-[#BEBEBE] tw-text-[13px] md:tw-text-[18px]">
            &gt;
          </div>
        </div>
        <div className="tw-flex tw-gap-[10px] tw-items-center tw-mb-[22px] md:tw-mb-[49px]">
          <div
            className={`${
              isActive(2) ? "tw-bg-electricGreen" : "tw-bg-[#BEBEBE]"
            } tw-h-[17px] tw-w-[17px] md:tw-h-[23px] md:tw-w-[23px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-white tw-text-[10px]`}
          >
            2
          </div>
          <div
            className={`${
              isActive(2) ? "tw-text-electricGreen" : "tw-text-[#BEBEBE]"
            } tw-text-[8px] md:tw-text-[18px]`}
          >
            Design card
          </div>
          {currentUser.account.type != ACCOUNT_TYPE.ENTERPRISE && (
            <div className="tw-mr-[15px] tw-ml-[5px] tw-text-[#BEBEBE] tw-text-[13px] md:tw-text-[18px]">
              &gt;
            </div>
          )}
        </div>
        {currentUser.account.type != ACCOUNT_TYPE.ENTERPRISE && (
          <div className="tw-flex tw-gap-[10px] tw-items-center tw-mb-[22px] md:tw-mb-[49px]">
            <div
              className={`${
                isActive(3) ? "tw-bg-electricGreen" : "tw-bg-[#BEBEBE]"
              } tw-h-[17px] tw-w-[17px] md:tw-h-[23px] md:tw-w-[23px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-white tw-text-[10px]`}
            >
              3
            </div>
            <div
              className={`${
                isActive(3) ? "tw-text-electricGreen" : "tw-text-[#BEBEBE]"
              } tw-text-[8px] md:tw-text-[18px]`}
            >
              Choose subscription
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Stepper;
