import React, { useState } from "react";
import images from "assets/images/Official.png";
import { useAuth } from "context/auth";
import { Link, useNavigate } from "react-router-dom";
import CardHoldersLimit from "./CardHoldersLimit";
import DaysLeft from "components/days-left";
import { Dialog } from "@headlessui/react";
// import { SUBSCRIPTION_PERIODS } from "layouts/authentication/register/organisation/subscription/data/plans";

function MyAccountButton() {
  return (
    <Link className="" to="/account">
      <button
        className="tw-rounded-regular focus:tw-outline-none focus:tw-ring-transparent tw-w-full tw-bg-mint tw-text-white tw-text-medium tw-py-[10px] tw-px-[45px] tw-undefined"
        type="button"
      >
        My account
      </button>
    </Link>
  );
}

function Logout() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <button
      className="tw-rounded-regular focus:tw-outline-none focus:tw-ring-transparent tw-w-full tw-text-black tw-text-medium tw-p-[10px] tw-undefined"
      type="button"
      onClick={() => {
        logout();
        navigate("/login");
      }}
    >
      Logout
    </button>
  );
}

function Header() {
  const [open, setOpen] = useState(false);
  const { currentUser } = useAuth();

  return (
    <nav className="tw-relative">
      <div className="tw-mx-auto md:tw-mb-16 tw-mb-8">
        <div className="tw-relative tw-flex tw-items-center">
          <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div className="tw-flex tw-items-center">
              <a className="" href="/">
                <img className="tw-w-32" src={images} alt="" />
              </a>
              {/* {currentUser.account?.subscription?.plan?.subscriptionPeriod ==
                SUBSCRIPTION_PERIODS.ANNUALLY && <DaysLeft />} */}
            </div>
            <div className="tw-flex tw-items-center tw-justify-end tw-gap-[15px]">
              <div className="tw-hidden sm:tw-block">
                <div className="tw-flex tw-space-x-4">
                  <CardHoldersLimit />
                  <MyAccountButton />
                  <div>
                    <Logout />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-justify-end sm:tw-hidden tw-text-sm">
            {/* {open ? (
              <div onClick={() => setOpen(false)}>
                <CloseIcon />
              </div>
            ) : ( */}
            <button type="button" onClick={() => setOpen(true)}>
              <span className="tw-sr-only">Open main menu</span>
              <span className="tw-block tw-border tw-border-brand hover:tw-border-brand-400 tw-rounded-md tw-shadow-sm tw-px-3 tw-py-1 tw-text-sm tw-text-brand">
                Menu
              </span>
            </button>
            {/* )} */}
          </div>
        </div>
      </div>
      <Dialog
        class="tw-border tw-border-slate-300 tw-bg-white tw-drop-shadow sm:tw-hidden tw-absolute tw-p-2 tw-right-7 tw-space-y-2 tw-top-14 tw-rounded-md tw-z-2"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Dialog.Overlay className={"tw-pointer-events-none tw-bg-slate-500"} />
        <Dialog.Panel>
          <CardHoldersLimit />
          <div>
            <MyAccountButton />
          </div>
          <Logout />
        </Dialog.Panel>
      </Dialog>
    </nav>
  );
}

export default Header;
