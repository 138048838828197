import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  url: "",
};

export const androidSlice = createSlice({
  name: "android",
  initialState,
  reducers: {
    saveGoogleUrl: (state, action) => {
      const { url } = action.payload;

      state.url = url;
    },
    clearGoogleUrl: (state) => {
      state.url = "";
    },
  },
});

export const { saveGoogleUrl, clearGoogleUrl } = androidSlice.actions;

export default androidSlice.reducer;
